exports.linkResolverV3 = (doc) => {
  // URL for a category type
  if (doc.type === 'category') {
    return `/category/${doc.uid}`;
  }

  // URL for a product type
  if (doc.type === 'product') {
    return `/product/${doc.uid}`;
  }

  // URL for a page type
  if (doc.type === 'page') {
    return `/${doc.uid}`;
  }

  if (doc.type === 'blog_post') {
    return `/blog/${doc.uid}`;
  }

  if (doc.type === 'newspost') {
    return `/news/${doc.uid}`;
  }

  if (doc.url === '/') {
    return `/${doc.uid}`;
  }

  // Backup for all other types
  return '/';
};
