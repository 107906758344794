import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { PrismicRichText } from '@prismicio/react';
import { htmlSerializer } from '../utils/htmlSerializer';
import Layout from '../components/layout';
import FullWidthContainer from '../components/containers/fullWidthContainer';
import LinkButton from '../components/linkButton';
import HeroContent from '../components/heroContent';
import FullWidthImageCollection from '../components/images/fullWidthImageCollection';
import SectionTitle from '../components/sectionTitle';
import { RichText } from 'prismic-reactjs';
import { convertToBgImage } from 'gbimage-bridge';
import { linkResolver } from '../utils/linkResolver';
import { phoneNumberHtmlSerializer } from '../utils/phoneNumberHtmlSerializer';
import { SliceZone } from '../sliceZone';
import { linkResolverV3 } from '../utils/linkResolverV3';

const BodySectionLarge = ({
  images,
  title,
  description,
  ctaLinkUrl,
  ctaLinkText,
  slices,
}) => {
  return (
    <section className='hidden bg-white lg:block mega:py-8'>
      <SectionTitle text={title} usePrimaryTextColor />
      <FullWidthImageCollection
        className='sm:bottom-slant-lg'
        images={images}
      />
      <div className='top-slant mega:top-slant-mega slant-margin-sm mb-8 -mt-8 flex flex-col items-center bg-denimblue px-48 text-justify leading-relaxed text-white md:-mt-16 lg:-mt-24 giant:-mt-32'>
        <div className='mt-32'>
          <PrismicRichText field={description} components={htmlSerializer} />
        </div>
        <div className='my-12 w-full text-center md:w-1/3'>
          <LinkButton to={ctaLinkUrl} label={ctaLinkText} />
        </div>
      </div>
      <SliceZone slices={slices} />
    </section>
  );
};

const BodySectionSmall = ({
  images,
  title,
  description,
  ctaLinkUrl,
  ctaLinkText,
  slices,
}) => {
  return (
    <section className='bg-white lg:hidden mega:py-8'>
      <SectionTitle text={title} usePrimaryTextColor />
      <div className='top-slant slant-margin-sm mb-8 flex flex-col bg-denimblue px-8 pt-16 pb-8 text-left leading-relaxed text-white'>
        <PrismicRichText field={description} components={htmlSerializer} />
      </div>
      <FullWidthImageCollection
        className='lg:bottom-slant-lg'
        images={images}
      />
      <div className='top-slant -mt-8 mb-8 flex flex-col items-center bg-denimblue px-6 pt-8 text-center text-white md:-mt-10 lg:-mt-16 mega:-mt-20'>
        <div className='my-12 w-full text-center md:w-1/3'>
          <LinkButton to={ctaLinkUrl} label={ctaLinkText} />
        </div>
      </div>
      <SliceZone slices={slices} />
    </section>
  );
};

const IndexPage = ({ data }) => {
  const {
    hero_title,
    banner_graphic,
    banner_image,
    banner_description_content,
    images,
    section_title,
    section_text,
    next_page_link,
    call_to_action_link_text,
    body,
  } = data.prismicLandingPage.data;

  const backgroundImage = convertToBgImage(banner_image.gatsbyImageData);

  const backgroundImageFluidStack = [`${banner_graphic.url}`, backgroundImage];

  const ctaLink = linkResolver(next_page_link);

  const {
    announcement_image,
    announcement_title,
    announcement_excerpt,
    announcement_url,
  } = body[0].primary;

  return (
    <Layout
      pageTitle={RichText.asText(hero_title.richText)}
      backgroundImageFluidStack={backgroundImageFluidStack}
      logoClassName='max-w-xxxxs xl:max-w-xxxs mega:max-w-xxs'
      heroSlantClassName='hero-slant lg:hero-slant-lg'
      isBannerVisible={true}
      contentInsideBackground={() => (
        <FullWidthContainer className='flex flex-col items-center justify-between pb-4 lg:flex-row xl:mt-6 mega:mt-16'>
          <HeroContent
            heroTitle={RichText.asText(hero_title.richText)}
            heroDescription={banner_description_content.richText}
          />
          <div className='mb-12 w-full rounded bg-white px-4 py-3 text-sm shadow-lg lg:w-1/3 mega:mr-16 mega:w-1/5'>
            <div className='my-4 flex flex-col justify-between lg:my-2'>
              <Link
                className='flex items-center justify-around mb-4'
                to={linkResolverV3(announcement_url)}
              >
                <h1 className='text-lg font-semibold text-opaqueblue'>
                  {RichText.asText(announcement_title.richText)}
                </h1>
              </Link>
              <PrismicRichText
                field={announcement_excerpt.richText}
                components={{
                  paragraph: ({ children }) => (
                    <p className='text-denimblue'>{children}</p>
                  ),
                  hyperlink: phoneNumberHtmlSerializer,
                }}
              />
              <Link to={linkResolverV3(announcement_url)} className="flex items-center text-denimblue underline mt-4">Read More</Link>
            </div>
          </div>
        </FullWidthContainer>
      )}
    >
      <BodySectionLarge
        images={images}
        title={RichText.asText(section_title.richText)}
        description={section_text.richText}
        ctaLinkUrl={ctaLink.url}
        ctaLinkText={RichText.asText(call_to_action_link_text.richText)}
        slices={body}
      />
      <BodySectionSmall
        images={images}
        title={RichText.asText(section_title.richText)}
        description={section_text.richText}
        ctaLinkUrl={ctaLink.url}
        ctaLinkText={RichText.asText(call_to_action_link_text.richText)}
        slices={body}
      />
    </Layout>
  );
};

export const query = graphql`
  query IndexPageQuery {
    prismicLandingPage {
      data {
        banner_description_content {
          richText
        }
        banner_graphic {
          url
          alt
          gatsbyImageData
        }
        banner_image {
          alt
          gatsbyImageData
        }
        images {
          image {
            alt
            gatsbyImageData
            url
          }
          image_large {
            alt
            gatsbyImageData
          }
        }
        call_to_action_link_text {
          richText
        }
        hero_title {
          richText
        }
        next_page_link {
          uid
          url
        }
        section_text {
          richText
        }
        section_title {
          richText
        }
        body {
          ... on PrismicLandingPageDataBodyTestimonial {
            slice_type
            primary {
              title {
                richText
              }
            }
            items {
              testimonial_author
              main_content {
                richText
              }
            }
          }
          ... on PrismicLandingPageDataBodyBalloonAnnoucement {
            primary {
              announcement_url {
                uid
                url
                type
              }
              announcement_title {
                richText
              }
              announcement_image {
                gatsbyImageData
                alt
              }
              announcement_excerpt {
                richText
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
