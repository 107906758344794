import React from 'react';
import { RichText } from 'prismic-reactjs';

const TestimonialSlice = (slice) => {
  const { primary, items } = slice.slice;

  return (
    <div className='mx-auto flex max-w-5xl flex-col px-3 md:px-0'>
      <div className='py-4'>
        <p className='text-center text-lg font-semibold uppercase text-denimblue'>
          {RichText.asText(primary.title.richText)}
        </p>
      </div>
      <div>
        {items.map((item, index) => {
          return (
            <div key={index} className='my-4 rounded p-4 shadow'>
              <p className='text-lg italic text-gray-600 md:text-xl'>
                "{RichText.asText(item.main_content.richText)}"
              </p>
              <p className='my-4 ml-4 italic text-gray-500 md:ml-8 md:text-lg'>
                -&nbsp; {item.testimonial_author}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TestimonialSlice;
