import React from 'react';
import TestimonialSlice from './components/slices/testimonialSlice';

export const SliceZone = ({ slices }) => {
  const sliceComponents = {
    testimonial: TestimonialSlice,
  };

  return slices.map((slice, index) => {
    const SliceComponent = sliceComponents[slice.slice_type];
    if (SliceComponent) {
      return <SliceComponent slice={slice} key={`slice=${index}`} />;
    }
    return null;
  });
};
