import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

const FullWidthImageCollection = (props) => {
  const { images, className } = props;

  return (
    <div className={`flex flex-col items-center md:flex-row ${className}`}>
      {images.map((image, index) => {
        return (
          <div
            key={index}
            className={`w-full lg:w-1/3 ${
              index % 2 !== 0 ? 'my-2 sm:mx-2 lg:mx-2' : ''
            }`}
          >
            <a href={image.image.url} rel='noopener noreferrer' target='_blank'>
              <GatsbyImage
                image={image.image.gatsbyImageData}
                className='lg:image-height-lg w-full min-w-0 object-cover'
                alt={image.image.alt}
              />
            </a>
          </div>
        );
      })}
    </div>
  );
};

export default FullWidthImageCollection;
