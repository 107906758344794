import React from 'react';

const SectionTitle = ({ text, usePrimaryTextColor, useWhiteTextColor }) => {
  let textColor = 'text-gray-700';

  if (usePrimaryTextColor) {
    textColor = 'text-denimblue';
  }
  if (useWhiteTextColor) {
    textColor = 'text-gray-100';
  }
  return (
    <div
      className={`my-6 px-2 pb-6 text-center text-2xl font-semibold uppercase leading-normal md:pt-6 md:pb-12 lg:pb-8 lg:text-4xl mega:text-3xl ${textColor}`}
    >
      <p>{text}</p>
    </div>
  );
};

export default SectionTitle;
