import React from 'react';
import { Link } from 'gatsby';

const LinkButton = ({ to, label }) => {
  return (
    <Link
      className='cursor-pointer rounded border-2 border-white px-8 py-3 uppercase text-white transition duration-100 ease-in-out hover:bg-white hover:text-denimblue'
      to={to}
    >
      {label}
    </Link>
  );
};

export default LinkButton;
